//import 'bootstrap';
require("./bootstrap");
//require("./axios");

import { createApp, defineAsyncComponent, computed, ref, h, nextTick } from "vue";
//import router from './router';
import store from './store';

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

import http from "./fromFetch";
//import http from "./axios-observable";
//import '../navbar';
//import "../app/toasts";
//import "../app/popovers";
//import { createApp, defineAsyncComponent } from "vue";
//import axios from "axios";
import moment from "moment";
//import auth from "./auth";
//import VueAuth from "@websanova/vue-auth";
//import Maska from 'maska';
//import store from "./store";
//import { useAppStore } from './store/modules/user.js'
//const appStore = useAppStore()
// vue toastification
//import Toast from "vue-toastification";
//import "vue-toastification/dist/index.css";
//import BootstrapVueNext from "bootstrap-vue-next";
import {createBootstrap} from 'bootstrap-vue-next'

// Add the necessary CSS
//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'

import { BootstrapIconsPlugin } from "bootstrap-icons-vue";
import Breadcrumb from "./components/home/Breadcrumb.vue";
//import Dialog from './components/Dialog.vue'
const Dialog = defineAsyncComponent(()=> import('./components/Dialog.vue')) ;
const Notification = defineAsyncComponent(()=> import('./components/Notification.vue')) ;
// const Blinker = defineAsyncComponent(()=> import('./components/Blinker.vue')) ;
//import '~material-icons/iconfont/material-icons.css';
//import { Dialog } from './components/Dialog/index.ts'
//import KnobControl from "../components/KnobControl.vue";
//import RoundSlider from "../components/ScrollToTop.vue";
//import ScrollToTop from "./components/ScrollToTop.vue";
//import Summernote from "./components/Summernote.vue";
//import bootstrap;
//import "popper.js"
//import "bootstrap"
//import "summernote/dist/summernote.css"
//import "summernote"

//import ElementPlus from 'element-plus'

//const ElementPlus = defineAsyncComponent(()=> import('element-plus')) ;
import ElementPlus from "element-plus";
import "element-plus/theme-chalk/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
//import './styles/element/index.scss'
//import 'overlayscrollbars/overlayscrollbars.css';
//import { OverlayScrollbars } from 'overlayscrollbars';
//import SummernoteEditor from 'vue3-summernote-editor';
//import SummernoteEditor from "./components/SummernoteEditor.vue";
const SummernoteEditor = defineAsyncComponent(()=> import('./components/SummernoteEditor.vue')) ;
//const SummernoteEditor = defineAsyncComponent(()=> import('./components/Editor.vue')) ;
//import Editor from "./components/Editor.vue";
const IconFload = defineAsyncComponent(() =>
    import("./components/IconFload.vue")
);
//import IconFload from "./components/IconFload.vue";
//import BootstrapTable from "./plugins/table";
//import ProductZoomer from "./components/ProductZoomer.vue";
//import izoomfancybox from "./components/izoomfancybox.vue";
const izoomfancybox = defineAsyncComponent(() =>
    import("./components/izoomfancybox.vue")
);
//import MenuBar from "./components/MenuBar.vue";
//import VideoPlayer from "./components/VideoPlayer.vue";
//import ImagePicker from "./components/ImagePicker.vue";
//import SelectImage from "./components/SelectImage.vue";
const SelectImage = defineAsyncComponent(() =>
    import("./components/SelectImage.vue")
);
//import VCalendar from 'v-calendar';
//import VPip from "v-pip";
//import StarRating from "vue-star-rating";
const Rating = defineAsyncComponent(() => import("./components/Rating.vue"));
//import Notifications from "@kyvg/vue3-notification";
//import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
//import "bootstrap-vue/dist/bootstrap-vue.css";
// init Vue 3
//import Summernote from 'vue3-summernote-editor';

//import SummernoteEditor from 'vue3-summernote-editor';
//const SummernoteEditor = defineAsyncComponent(()=> import('vue3-summernote-editor')) ;

moment.locale("id");
const aLink = defineAsyncComponent(()=> import('./components/aLink.vue')) ;
const HeartBeat = defineAsyncComponent(()=> import('./components/HeartBeat.vue')) ;
//const printJS = ()=> import('print-js');
//import printJS from "print-js";
/* {
    props: ['item'],
    render() {
        return h('a', { href: item.href }, this.$slots.default())
    }
} */
//import MySideBar from "./pages/dashboard/MySideBar.vue";
//import SideBar from "./pages/dashboard/SideBar.vue";
//import QrcodeVue from 'qrcode.vue'
const QrcodeVue = defineAsyncComponent(()=> import('qrcode.vue')) ;
import SidebarMenu from "./pages/dashboard/sidebar/components/SidebarMenu.vue";
//const SidebarMenu = defineAsyncComponent(()=> import('vue-sidebar-menu')) ;
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

let numberFormat = function (value) {
    let val = (value / 1).toFixed(0).replace(".", ",");
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

import VueCookies from 'vue-cookies'

//import { UseDark } from '@vueuse/components'
//import { useDark, useToggle } from '@vueuse/core';
import { useColorMode, useCycleList } from "@vueuse/core";
import  VueHtmlToPaper from './plugins/VueHtmlToPaper'
const app = createApp({
    mixins: [appMixin, myMixin, menuMixin],
    components: {
        //KnobControl, RoundSlider,
        //BootstrapTable,
        //BootstrapVueNext,
        QrcodeVue,
        FontAwesomeIcon,
        Breadcrumb,
        QuillEditor,
        HeartBeat,
        Dialog,
        aLink,
        // Blinker,
        Notification,
        //MySideBar,
        SidebarMenu,
        //ScrollToTop,
        //MyTable,
        //Summernote,
        //ElementPlus,
        SummernoteEditor,
        //Editor,
        izoomfancybox, //xzoom
        //VideoPlayer,
        IconFload,
        //DarkModeSwitch,
        //StoreCarousel,
        //MenuBar,
        //Card,
        //CardItem,
        Rating,
        //CarouselSlide,
        //Category,
        //InputNumber,
        //Breadcrumb,
        //ChitietSanpham,
        //SelectImage,
        //InputMask,
        //SummernoteEditor,
        //CarouselClickToSlide,
    },
    setup() {
        const mode = useColorMode({
            emitAuto: true,
            selector: 'body',
            modes: {
                contrast: "dark contrast",
                cafe: "cafe",
            },
        });
        const { next } = useCycleList(['dark', 'light', 'cafe', 'contrast'], { initialValue: mode });
        return {
            mode,
            next,
        };
    },
    mounted () {
        this.onScreenResize()
        window.addEventListener('resize', this.onScreenResize, { passive: true })
    },
    data() {
        return {
            activeIndex: "1",

            asideWidth: "200px",
            //sideBarToggle: true,
            isDarkModeEnabled: true,
            isFullscreen: false,
            isMobile: false,
        };
    },
    methods:{
        toggleFull(){
            this.isFullscreen=!this.isFullscreen
        },
        onScreenResize () {
            this.isMobile = window.innerWidth < 768
        },
/*         printer(id){
            printJS(id, 'html')
        }, */
    },
    computed: {
        iconFullscreen (){
            return this.isFullscreen ? "b-icon-fullscreen-exit" : "b-icon-fullscreen"
        }
    },
    beforeDestroy () {
        if (typeof window !== 'undefined') {
            window.removeEventListener('resize', this.onScreenResize, { passive: true })
        }
    },
})
    .use(ElementPlus, { size: "default", zIndex: 3000 })
    //.component('font-awesome-icon', FontAwesomeIcon)
    .use(store)
    //.use(router)
    .use(VueCookies, { expires: '60d'})
    .use(VueHtmlToPaper)
    //.use(BootstrapVueNext)
    .use(createBootstrap({components: true, directives: true})) // Change this line
    .use(BootstrapIconsPlugin);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}
//import dayjs from "dayjs";
//app.component("app", require("./views/App.vue").default);
//app.component("auth-page", require("./views/Auth.vue").default);
//app.use(vbPlugin);
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$http = http;
//app.config.globalProperties.$axios = axios;
//app.config.globalProperties.$httpx = httpx;
app.config.globalProperties.$numberFormat = numberFormat;
//app.directive('maska', Maska.maska);
window.app = app.mount("#app");
